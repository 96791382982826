import * as React from 'react'
import { navigate } from "gatsby"
import "./stylesheet.css"
import {INode} from "./tree-view/i-node";
import store from "../store";
import {Unsubscribe} from "redux";
import getTitle from "./i18nHelper";

const getPrevious = (): INode|undefined => {
    let childIndex: number = 0;
    let s = store.getState();
    let node = s.ui.treeView.currentNode;
    if(node == undefined) return undefined;
    // is the current node the first children?
    if (node.parent != undefined) {
        node.parent.children.forEach((child: INode, index: number) => {
            if (node.md === child.md) {
                childIndex = index;
            }
        });
        if (childIndex > 0) {
            return node.parent.children[childIndex-1];
        } else {
            return node.parent;
        }
    }
    return node;
};

const getNext = (): INode|undefined => {
    let childIndex: number = 0;
    let s = store.getState();
    let node = s.ui.treeView.currentNode;
    if(node == undefined) return undefined;
    // is the current node the first children?
    if (node.parent != undefined) {
        node.parent.children.forEach((child: INode, index: number) => {
            if (node.md === child.md) {
                childIndex = index;
            }
        });
        if (childIndex < node.parent.children.length) {
            return node.parent.children[childIndex+1];
        } else {
            return node.parent;
        }
    }
    return node;
};

interface TreeNavigatorState {
    language: string;
}

class TreeNavigator extends React.Component<{}, TreeNavigatorState> {
    sub: Unsubscribe;
    constructor(props: any) {
        super(props);
        let s = store.getState();
        this.state = {
            language: s.language.currentLanguage
        };
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();

            //store the state
            this.setState({
                language: s.language.currentLanguage
            });
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    onNavigate = (node: INode) => {
        if (node.md != undefined) {
            const url = `/${this.state.language}${node.md}`;
            navigate(url);
        }
    };

    render() {
        let previous: INode = getPrevious();
        let next: INode = getNext();
        let style = { cursor: 'pointer'};
        return (
            <ul id="tree-navigator"
                style={{
                    display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                }}>
                <li>
                    {previous && previous.md && (
                        <a style={style} onClick={() => {this.onNavigate(previous);return false;}}>
                            <span>
                                ← {getTitle(previous, this.state.language)}
                            </span>
                        </a>
                    )}
                </li>
                <li>
                    {next && next.md && (
                        <a style={style} onClick={() => {this.onNavigate(next);return false;}}>
                            <span>
                                {getTitle(next, this.state.language)} →
                            </span>
                        </a>
                    )}
                </li>
            </ul>
        )
    }
}

export default TreeNavigator
