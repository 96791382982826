import * as React from 'react';
import {FaChevronCircleDown, FaChevronCircleRight, FaChevronDown, FaChevronRight} from 'react-icons/fa';
import styled from 'styled-components';
import Link from 'gatsby-link';
import {INode} from "./i-node";
import "./stylesheet.css"

//border-top: #f9e4e9 solid ${props => props.node.level === 0 ? 1 : 0}px;
//border-bottom: #f9e4e9 solid ${props => props.node.level === 0 ? 1 : 0}px;

const getPaddingLeft = (node: INode): string => {
    if (node == undefined || node.level == undefined || node.level === 0) return '10px';
    return `${node.level * 10}px`;
};

const formatId = (node: INode): string => {
    if (node.id == undefined) return '-';
    return node.id.toString().replace((/\d/g), '$&.');
};

const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
  color: #ca492d;
    box-shadow: none;
    font-size: 0.8em;
    font-weight: 300;
    border-left: solid 3px transparent;

  &:hover {
    background: #fdeeee;
    text-decoration: underline;
    border-left: solid 3px #ca492d;
  }
  & > a {
    color: #ca492d;
    box-shadow: none;
    font-weight: 600;
    font-family: Lato;
    padding: 0;
  }
  & > a:hover,& > a:active {
    background-color: transparent;
  }
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${(props:any) => props.marginRight ? props.marginRight : 5}px;
  margin-top: 3px;
`;
interface TreeNodeProps {
    key: number;
    node: INode;
    onToggle: Function;
    onNavigate: Function;
    getChildNodes: Function;
    language: string;
}
export default class TreeNode extends React.Component<TreeNodeProps> {
    render() {
        const getTitle = () => {
            //i18n object might be not defined, then return default
            if (this.props.node.i18n == undefined) {
                return this.props.node.title;
            }
            //i18n entry for the current language might be not defined, then return default
            let title = this.props.node.i18n[this.props.language];
            //set the default value if no localization available
            title = title == undefined ? title = this.props.node.title : title;
            return title;
        };
        const chevronDown =
            <div className="chevron-down-container">
                <span className="chevron-down">
                    <FaChevronDown/>
                </span>
                <span className="chevron-down-hover">
                    <FaChevronCircleDown/>
                </span>
            </div>;
        const chevronRight =
            <div className="chevron-right-container">
                <span className="chevron-right">
                    <FaChevronRight/>
                </span>
                <span className="chevron-right-hover">
                    <FaChevronCircleRight/>
                </span>
            </div>;

        let content;
        if (this.props.node.md == undefined) {
            content =
                <React.Fragment>
                    <NodeIcon style={{display: 'inline-block'}} onClick={(e: any) => this.props.onToggle(this.props.node, e)}>
                        {this.props.node.children.length !== 0 && (this.props.node.isOpen ? chevronDown : chevronRight)}
                        {this.props.node.children.length === 0 ? <FaChevronDown style={{color: 'transparent'}}/> : <div></div>}
                    </NodeIcon>
                    <span title={getTitle()}>{getTitle()}</span>
                </React.Fragment>
        } else {
            content =
            <div style={{
                color: this.props.node.isActive ? '#ffffff' : undefined,
                fontFamily: 'Lato',
                fontWeight: '600'
            }}>
                <div style={{display: 'flex'}}>
                <NodeIcon style={{display: 'inline-block'}} onClick={(e: any) => this.props.onToggle(this.props.node, e)}>
                    {this.props.node.children != undefined && this.props.node.children.length !== 0 && (this.props.node.isOpen ? chevronDown : chevronRight)}
                    {this.props.node.children == undefined || this.props.node.children.length === 0 ? <FaChevronDown style={{color: 'transparent'}}/> : <div></div>}
                </NodeIcon>
                <div title={getTitle()} style={{display: 'inline-block'}}>
                    <span style={{color: this.props.node.isActive ? '#ffffff' : '#828181', fontSize: '0.8em'}}>{formatId(this.props.node)}</span>&nbsp;{getTitle()}</div>
                </div>
            </div>
        }
        return (
            <React.Fragment>
                <StyledTreeNode
                    onClick={() => this.props.onNavigate(this.props.node)}
                    style={{
                        paddingLeft: getPaddingLeft(this.props.node),
                        backgroundColor: this.props.node.isActive ? '#533094' : null,
                        color: this.props.node.isActive ? '#ffffff' : null,
                        borderLeft: this.props.node.isActive ? 'solid 3px #ca492d' : null,
                        overflowX: 'hidden'}}>
                    {content}
                </StyledTreeNode>

                {this.props.node.isOpen && this.props.getChildNodes(this.props.node).map((childNode: INode, i: number) => (
                    <TreeNode
                        key={i}
                        {...this.props}
                        node={childNode}
                    />
                ))}
            </React.Fragment>
        );
    }
}