import * as React from 'react'
import { navigate } from "gatsby";
import styled from 'styled-components';
import "./stylesheet.css"
import store from "../store";
import {setLanguage} from "../store/actions/languageActions";
import {Unsubscribe} from "redux";
import {INode} from "./tree-view/i-node";


const StyledDiv = styled.div`
  & {
  }
  & > a {
    cursor: pointer;
    color: #ca492d;
    box-shadow: none;
    font-weight: 600;
    font-family: Lato;
    font-size: 1.2em;
    padding: 4px 10px 5px;
    border-radius: 4px;
  }
  & > a:hover {
    background-color: #f9dede;
  }`;

interface LanguageSelectorState {
    currentNode: INode;
    language: string;
}

class LanguageSelector extends React.Component<{}, LanguageSelectorState> {
    sub: Unsubscribe;
    constructor(props: any) {
        super(props);
        let s = store.getState();
        this.state = {
            currentNode: s.ui.treeView.currentNode,
            language: s.language.currentLanguage};
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();
            this.setState( {
                currentNode: s.ui.treeView.currentNode,
                language: s.language.currentLanguage
            });
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    render() {
        const changeLanguage = (language: string) => {
            store.dispatch(setLanguage(language));

            //navigate to the current page in the new selected language
            const url = `/${language}${this.state.currentNode.md}`;
            navigate(url);
        };

        const getStyle = (language: string) => {
            return {
                backgroundColor: this.state.language === language ? '#ca492d' : 'transparent',
                color: this.state.language === language ? '#ffffff' : '#ca492d'
            };
        };
        return (
            <StyledDiv style={{fontFamily:'Lato',color:'#ca492d',fontSize:'0.7em'}}>
                <a style={getStyle('de')}
                   href={""}
                   onClick={()=> {changeLanguage('de');return false;}}>de</a>
                <a style={getStyle('en')}
                    href={""}
                   onClick={()=> {changeLanguage('en');return false;}}>en</a>
                <a style={getStyle('fr')}
                   href={""}
                   onClick={()=> {changeLanguage('fr');return false;}}>fr</a>
            </StyledDiv>
        )
    }
}

export default LanguageSelector
