import {SET_CONNECTION} from "../actions/connectionActions";

const initialState = {
    isOnline: false
};
function connectionReducer(state = initialState, action: any) {
    if (action.type === SET_CONNECTION) {
        return Object.assign({}, state,
            { isOnline: action.payload.isOnline});
    }
    return state;
};
export default connectionReducer;