//https://github.com/davidtran/simple-treeview
import * as React from 'react'

import TreeNode from './TreeNode';
import { navigate } from "gatsby";
import {INode} from "./i-node";
import store from "../../store";
import {resizeWindowLeftPane} from "../../store/actions";
import {Unsubscribe} from "redux";

interface TreeProps {
    onSelect: Function;
}
interface TreeState {
    rootNode: INode;
    language: string;
}
export default class Tree extends React.Component<TreeProps, TreeState> {
    sub: Unsubscribe;
    constructor(props: TreeProps) {
        super(props);
        let s = store.getState();
        this.state = {
            rootNode: s.ui.treeView.rootNode,
            language: s.language.currentLanguage
        };
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();
            // set the active node every time the current node is changed
            if(s.ui.treeView.currentNode != undefined) {
                this.setActiveNode(this.state.rootNode, s.ui.treeView.currentNode.id);
            }

            //store the state
            this.setState({
                language: s.language.currentLanguage
            });
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    getRootNode = (): INode => {
        const s = store.getState();
        const rootNode = s.ui.treeView.rootNode;
        // set the level for the root node
        if (rootNode.level == undefined) rootNode.level = 0;
        // set the level and the id for the children nodes
        const childLevel: number = rootNode.level + 1;
        rootNode.children.forEach((node: INode, index: number) => {
            node.level = childLevel;
            node.id = index+1;
            node.parent = rootNode;
        });
        rootNode.areChildrenLoaded = true;
        return rootNode;
    };

    toggleOpenFlag = (nodes: INode, node: INode) => {
        if(nodes.id === node.id) {
            nodes.isOpen = !nodes.isOpen;
            return nodes;
        }
        if (nodes.children == undefined) return nodes;
        nodes.children.forEach((n) => {
            this.toggleOpenFlag(n, node);
        });
        return nodes;
    };

    setActiveNode = (nodes: INode, id: number) => {
        if(nodes == undefined) return;
        //set the isActive flag for every node in the tree
        if (id != undefined && nodes.id === id) {
            nodes.isActive = true;
            //open the parent nodes recursively
            let recursiveNode = Object.assign({}, nodes);
            while(recursiveNode.parent != undefined) {
                recursiveNode.parent.isOpen = true;
                recursiveNode = recursiveNode.parent;
            }
        } else {
            nodes.isActive = false;
        }
        if(!nodes.areChildrenLoaded) {
            this.getChildNodes(nodes);
        }
        if (nodes.children == undefined) return nodes;
        nodes.children.forEach((child) => {
            this.setActiveNode(child, id);
        });
        return nodes;
    };

    getChildNodes = (node: INode) => {
        if (node == undefined || !node.children) return [];
        // set the id, level & parent for each child node
        node.children.forEach((childNode: INode, index: number) => {
            childNode.level = node.level + 1;
            childNode.id = (node.id*10) + (index+1);
            childNode.parent = node;
        });
        node.areChildrenLoaded = true;
        return node.children;
    };

    onToggle = (node: INode, event: any) => {
        event.preventDefault();
        event.stopPropagation();
        // modify the state: toggle the open flag
        let rootNode = this.state.rootNode;
        rootNode = this.toggleOpenFlag(this.state.rootNode, node);
        this.setState({ rootNode: rootNode });
    };

    onNavigate = (node: INode) => {
        // navigate if defined
        if (node.md != undefined) {
            const url = `/${this.state.language}${node.md}`;
            navigate(url);

            //close the left pane
            store.dispatch(resizeWindowLeftPane(window.innerWidth));
        }
    };

    onNodeSelect = (node: INode) => {
        this.props.onSelect(node);
    };

    render() {
        const rootNode: INode = this.getRootNode();
        return (
            <div style={{ marginTop: '20px'}}>
                { rootNode.children.map((node: INode, index: number) => (
                    <TreeNode
                        key={index}
                        node={node}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNavigate={this.onNavigate}
                        language={this.state.language}
                    />
                ))}
            </div>
        )
    }
}