import {
    INIT_LEFT_PANE, INIT_TREE_VIEW,
    RESIZE_WINDOW_LEFT_PANE, SET_CURRENT_NODE,
    TOGGLE_LEFT_PANE
} from "../actions";
import {INode} from "../../components/tree-view/i-node";

export interface IUi {
    leftPane: ILeftPanel;
    treeView: ITreeView;
}
export interface ILeftPanel {
    isVisible: boolean;
    displayBackDrop: boolean;
    maxWidth: number;
}
export interface ITreeView {
    rootNode: INode;
    currentNode: INode;
}
const initialState = {
    leftPane: {
        isVisible: false,
        displayBackDrop: false,
        maxWidth: 0
    },
    treeView: {
        rootNode: {title:'',children:[]},
        currentNode: {title:'',children:[]}
    }
};
function uiReducer(state = initialState, action: any) {
    /*
    The function must compare a path with language, with a path without language
        /fr/quick-tour/abc
        /de/quick-tour/abc -> must match with /quick-tour/abc
     */
    let getNodeFromPath = (nodes: INode, path: string): INode|undefined => {
        //remove the trailing slashes from path
        let md = nodes.md == undefined ? '' : nodes.md;
        md = md.replace(/\/$/, "");
        //remove the language from the path
        //match[1] : the path without the language
        //const myRegexp = /^\/\w{2}(\/[a-zA-Z-\/]+)/g;
        //const match = myRegexp.exec(path);
        const regex = new RegExp("^/\\w{2}(/[a-zA-Z-/]+)", "g");
        const match = regex.exec(path);
        if(match != undefined && md === match[1]) {
            return nodes;
        }
        let found;
        if (nodes.children == undefined) return undefined;
        for (let child of nodes.children) {
            found = getNodeFromPath(child, path);
            if(found != undefined) {
                break;
            }
        }
        return found;
    };

    if (action.type === INIT_LEFT_PANE) {
        return Object.assign({}, state, {
            leftPane: Object.assign({},{
                maxWidth: action.payload.maxWidth,
                isVisible: action.payload.windowWidth > action.payload.maxWidth,
                displayBackDrop: false
                }
            )
        });
    } else if (action.type === RESIZE_WINDOW_LEFT_PANE) {
        return Object.assign({}, state, {
            leftPane: Object.assign({}, state.leftPane, {
                    isVisible: action.payload.windowWidth > state.leftPane.maxWidth,
                    displayBackDrop: false
                }
            )
        });
    } else if (action.type === TOGGLE_LEFT_PANE) {
        return Object.assign({}, state, {
                leftPane: Object.assign({},state.leftPane,{
                    isVisible: !state.leftPane.isVisible,
                    displayBackDrop: !state.leftPane.isVisible
                })
        });
    } else if (action.type === SET_CURRENT_NODE) {
        const node = getNodeFromPath(state.treeView.rootNode, action.payload.path);
        return Object.assign({}, state, {
                treeView: Object.assign({},state.treeView,{
                    currentNode: node
                })
        });
    } else if (action.type === INIT_TREE_VIEW) {
        /*if (state.ui.treeView.rootNode.id != undefined) {
            return Object.assign({}, state);
        }*/
        return Object.assign({}, state, {
            treeView: Object.assign({},state.treeView,{
                rootNode: action.payload.node
            })
        });
    }
    /*if (action.type === ADD_ARTICLE) {
        return Object.assign({}, state, {
            articles: state.articles.concat(action.payload)
        });
    }*/
    return state;
};
export default uiReducer;
