import {combineReducers, createStore} from "redux";
import uiReducer from "./reducers/index";
import languageReducer from "./reducers/languageReducer";
import connectionReducer from "./reducers/connectionReducer";
const rootReducer = combineReducers({
    ui: uiReducer,
    language: languageReducer,
    connection: connectionReducer
})
const store = createStore(rootReducer);
export default store;