import gray from 'gray-percentage'
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'

const theme = {
  title: 'Theme jm',
  baseFontSize: '16px',
  baseLineHeight: 1.75,
  scaleRatio: 5 / 2,
  googleFonts: [
    {
      name: 'Lato',
      styles: ['400'],
    },
    {
      name: 'Open Sans',
      styles: ['400', '400i', '700', '700i', '900', '900i'],
    },
  ],
  headerFontFamily: ['Lato', 'Georgia', 'serif'],
  bodyFontFamily: ['Open Sans', 'Georgia', 'serif'],
  bodyColor: 'hsla(0,0%,0%,0.9)',
  headerWeight: 900,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
      html: {overflowY: 'auto'},
    h1: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    blockquote: {
      ...scale(1 / 5),
      color: gray(41),
      fontStyle: 'italic',
      paddingLeft: rhythm(13 / 16),
      marginLeft: rhythm(-1),
      borderLeft: `${rhythm(3 / 16)} solid #937dba`,
    },
    'blockquote > :last-child': {
      marginBottom: 0,
    },
    'blockquote cite': {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    'blockquote cite:before': {
      content: '"— "',
    },
    ul: {
      listStyle: 'disc',
    },
      'ul li': {
        paddingLeft: 0,
        marginLeft: '20px'
    },
      'ol li': {
          paddingLeft: 0,
          marginLeft: '20px'
      },
    'ul,ol': {
      marginLeft: 0,
    },
    [MOBILE_MEDIA_QUERY]: {
      'ul,ol': {
        marginLeft: rhythm(1),
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    'h1,h2,h3,h4,h5,h6': {
      marginTop: rhythm(1.2),
    },
      'h2,h3,h4,h5,h6': {
          color: '#533094'
      },
      h2: {
          fontWeight: 400,
            fontStyle: 'normal'
      },
    h4: {
      letterSpacing: '0.140625em',
      textTransform: 'uppercase',
    },
    h6: {
      fontStyle: 'italic',
    },
    a: {
      boxShadow: '0 3px 0 0 currentColor',
      color: '#ca492d',
      textDecoration: 'none',
        fontWeight: 600,
        padding: '5px',
        paddingBottom: '0'
    },
    'a:hover,a:active': {
      boxShadow: 'none',
        backgroundColor: '#f9dede',
        paddingBottom: '5px',
        borderRadius: '4px'
    },
    'mark,ins': {
      background: '#007acc',
      color: 'white',
      padding: `${rhythm(1 / 16)} ${rhythm(1 / 8)}`,
      textDecoration: 'none',
    },
  }),
}

export default theme
