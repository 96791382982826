import * as React from 'react'
import "./stylesheet.css"
import store from "../store";
import {INode} from "./tree-view/i-node";
import {Unsubscribe} from "redux";
import getTitle from "./i18nHelper";

interface BreadCrumbState {
    currentNode: INode;
    language: string;
}

class BreadCrumb extends React.Component<{}, BreadCrumbState> {
    sub: Unsubscribe;
    constructor(props: any) {
        super(props);
        let s = store.getState();
        this.state = {
            currentNode: s.ui.treeView.currentNode,
            language: s.language.currentLanguage
        };
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();

            //store the state
            this.setState({
                currentNode: s.ui.treeView.currentNode,
                language: s.language.currentLanguage
            });
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    render() {
        const getPath = (): string => {
            let path = [];
            let node = this.state.currentNode;
            if(node == undefined) return '';
            path.push(getTitle(node, this.state.language));
            while(node.level != undefined && node.level > 1) {
                node = node.parent;
                path.unshift(getTitle(node, this.state.language));
            }

            let result = '';
            path.forEach((value: string) => {
                if(result.length === 0) {
                    result = value;
                } else {
                    result = `${result} > ${value}`;
                }
            });
            return result;
        };

        return (
            <div style={{fontFamily:'Lato',color:'#ca492d',fontSize:'0.8em'}}>{getPath()}</div>
        )
    }
}

export default BreadCrumb
