import {INode} from "../../components/tree-view/i-node";

export const ADD_ARTICLE = "ADD_ARTICLE";

//left pane actions
export const INIT_LEFT_PANE = "INIT_LEFT_PANE";
export const RESIZE_WINDOW_LEFT_PANE = "RESIZE_WINDOW_LEFT_PANE";
export const TOGGLE_LEFT_PANE = "TOGGLE_LEFT_PANE";
export const INIT_TREE_VIEW = "INIT_TREE_VIEW";

//current node
export const SET_CURRENT_NODE = "SET_CURRENT_NODE";

export function addArticle(payload: any) {
    return { type: ADD_ARTICLE, payload }
};

export function initLeftPane(maxWidth: number, windowWidth: number) {
    return {
        type: INIT_LEFT_PANE,
        payload: {
            maxWidth: maxWidth,
            windowWidth: windowWidth
        }
    }
}
export function resizeWindowLeftPane(windowWidth: number) {
    return {
        type: RESIZE_WINDOW_LEFT_PANE,
        payload: {
            windowWidth: windowWidth
        }
    }
}
export function toggleLeftPane() {
    return { type: TOGGLE_LEFT_PANE }
}

export function initTreeView(node: INode) {
    return {
        type: INIT_TREE_VIEW,
        payload: {
            node: node
        }
    }
}

export function setCurrentNode(path: string) {
    return {
        type: SET_CURRENT_NODE,
        payload: {
            path: path
        }
    }
}