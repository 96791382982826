/*The max window width where the left pane is displayed
* window width <= MIN_WIDTH_DISPLAY_LEFT_PANE: hide left pane
* window width > MIN_WIDTH_DISPLAY_LEFT_PANE: display left pane
* */
export const MIN_WIDTH_DISPLAY_LEFT_PANE = 600;

/* The languages allowed in the app */
export const ALLOWED_LANGUAGES = ['de', 'en', 'fr'];

/* The default language used when trying to set an unallowed language */
export const DEFAULT_LANGUAGE = 'en';