export const SET_CONNECTION = "SET_CONNECTION";

export function setConnection(isOnline: boolean) {
    return {
        type: SET_CONNECTION,
        payload: {
            isOnline: isOnline
        }
    }
};
