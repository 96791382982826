import * as React from 'react'
import { navigate } from "gatsby"
import styled from 'styled-components';
import "./stylesheet.css"
import {INode} from "./tree-view/i-node";
import store from "../store";
import {Unsubscribe} from "redux";
import getTitle from "./i18nHelper";

const StyledUl = styled.ul`
  & {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 700px) {
    & {
        display: table;
    }
  }
`;

const StyledLi = styled.li`
  & {
    margin: 5px 0;
    border-radius: 4px;
  }
  & > a {
    cursor: pointer;
    color: #ffffff;
    box-shadow: none;
    font-weight: 100;
    font-family: Lato;
    font-size: .9em;
    padding: 10px 20px;
   
  }
  & > a:hover {
    background-color: transparent;
    text-decoration: unset !important;
  }`;

interface TreeNavigatorState {
    currentNode: INode;
    language: string;
}

class ChildrenNavigator extends React.Component<{}, TreeNavigatorState> {
    sub: Unsubscribe;
    constructor(props: any) {
        super(props);
        let s = store.getState();
        this.state = {
            currentNode: s.ui.treeView.currentNode,
            language: s.language.currentLanguage
        };
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();

            //store the state
            this.setState({
                currentNode: s.ui.treeView.currentNode,
                language: s.language.currentLanguage
            });
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    onNavigate = (node: INode) => {
        if (node.md != undefined) {
            const url = `/${this.state.language}${node.md}`;
            navigate(url);
        }
    };

    render() {
        let lis;
        if(this.state.currentNode != undefined
            && this.state.currentNode.parent != undefined
            && this.state.currentNode.parent.children != undefined) {
            //let style = { cursor: 'pointer', boxShadow: 'none', color: '#ffffff', fontSize: '0.8em'};
            lis = this.state.currentNode.parent.children.map((node: INode, index: number) => (
                <StyledLi
                    key={index}
                    style={{border: node.id === this.state.currentNode.id ? 'solid 1px #ffffff' : 'none'}}>
                    {node && node.md && (
                        <a onClick={() => {this.onNavigate(node);return false;}}
                           style={{textDecoration: node.id === this.state.currentNode.id ? 'unset' : 'underline'}}>
                            {getTitle(node, this.state.language)}
                        </a>
                    )}
                </StyledLi>
            ));
        }
        return (
            <div style={{backgroundColor: '#532c96', padding: '50px'}}>
                <div>
                    <StyledUl>
                        {lis}
                    </StyledUl>
                </div>
            </div>
        )
    }
}

export default ChildrenNavigator
