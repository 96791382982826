import {INode} from "./tree-view/i-node";

const getTitle = (node: INode, language: string) => {
    //i18n object might be not defined, then return default
    if (node.i18n == undefined) {
        return node.title;
    }
    //i18n entry for the current language might be not defined, then return default
    let title = node.i18n[language];
    if(title == undefined) {
        title = node.title;
    }
    return title;
};

export default getTitle;