import {INode} from "./i-node";

const navigationRoot: INode = {
    id: 0,      // do not remove
    level: 0,   // do not remove
    title: 'root',
    md: '',

    children: [
        {
            title: 'Introduction',
            i18n: {
                de: 'Einführung'
            },
            md: '/introduction'},
        {
            title: 'Tour Guidé',
            i18n: {
                de: 'Schnelle Tour',
                en: 'Quick tour'
            },
            md: '/quick-tour',
            children: [
                {
                    title: 'Présentation',
                    i18n: {
                        de: 'Einführung',
                        en: 'Overview'
                    },
                    md: '/quick-tour/overview',
                    children: []},
                {
                    title: 'Inscription',
                    i18n: {
                        de: 'Anmeldung',
                        en: 'Login'
                    },
                    md: '/quick-tour/login',
                    children: []},
                {
                    title: 'Espace de travail',
                    i18n: {
                        de: 'Workspace',
                        en: 'Workspace'
                    },
                    md: '/quick-tour/workspace',
                    children: []},
                {
                    title: 'Les diagrammes',
                    i18n: {
                        de: 'Diagrammen',
                        en: 'Diagrams'
                    },
                    md: '/quick-tour/display-person',
                    children: []},
                {
                    title: 'La ligne de vie',
                    i18n: {
                        de: 'Foto-Gallerie',
                        en: 'Photo gallery'
                    },
                    md: '/quick-tour/time-line',
                    children: []},
                {
                    title: 'Les quotas gratuits',
                    i18n: {
                        de: 'Gratis Quotas',
                        en: 'Free quotas'
                    },
                    md: '/quick-tour/free-quotas',
                    children: []}
            ]},
        {
            title: 'Guides',
            i18n: {
                de: 'Dokumentation'
            },
            md: '/guides',
            children: [
            {
                title: 'Authentification',
                md: '/guides/authentication',
                children: [
                    {
                        title: 'Inscription',
                        md: '/guides/authentication/signup',
                        children: []},
                    {
                        title: 'Login',
                        md: '/guides/authentication/login',
                        children: []},
                    {
                        title: 'Login après invitation',
                        md: '/guides/authentication/login-after-invitation',
                        children: []}
                ]},
                {
                    title: "Le mur d'images",
                    md: '/guides/image-wall',
                    children: [
                        {
                            title: 'Rechercher',
                            md: '/guides/image-wall/search'}
                    ]},
                {
                    title: 'Les arbres',
                    md: '/guides/trees',
                    children: [
                        {
                            title: 'Ajouter un arbre',
                            md: '/guides/trees/add-tree'},
                        {
                            title: 'Renommer un arbre',
                            md: '/guides/trees/rename-tree'},
                        {
                            title: 'Supprimer un arbre',
                            md: '/guides/trees/remove-tree'}
                    ]},
                {
                    title: 'Les données',
                    md: '/guides/persons',
                    children: [
                        {
                            title: 'Ajouter une personne seule',
                            md: '/guides/persons/add-person'},
                        {
                            title: 'Modifier une personne',
                            md: '/guides/persons/modify-person'},
                        {
                            title: 'Ajouter un parent',
                            md: '/guides/persons/add-parent'},
                        {
                            title: 'Ajouter un enfant',
                            md: '/guides/persons/add-child'},
                        {
                            title: 'Supprimer une personne',
                            md: '/guides/persons/remove-person'}
                    ]},
                {
                    title: 'Les documents',
                    md: '/guides/documents',
                    children: [
                        {
                            title: 'Importer',
                            md: '/guides/documents/import-document'},
                        {
                            title: 'Organisation',
                            md: '/guides/documents/organize-documents'},
                        {
                            title: 'Annotations',
                            md: '/guides/documents/annotations'}
                    ]},
                {
                    title: "Visualisation",
                    md: '/guides/visualization',
                    children: [
                        {
                            title: 'Diagramme circulaire',
                            md: '/guides/visualization/circular-diagram'},
                        {
                            title: 'Diagramme par arbre',
                            md: '/guides/visualization/tree-diagram'}
                    ]},
                {
                    title: "Collaboration",
                    md: '/guides/collaboration',
                    children: [
                        {
                            title: 'Invitation',
                            md: '/guides/collaboration/invite-someone'},
                        {
                            title: "Modifier les droits d'accès",
                            md: '/guides/collaboration/modify-access-rights'},
                        {
                            title: "Droits partiels",
                            md: '/guides/collaboration/partial-access-rights'}
                    ]},
                {
                    title: 'GEDCOM',
                    md: '/guides/gedcom',
                    children: [
                        {
                            title: 'Importer',
                            md: '/guides/gedcom/import-file'},
                        {
                            title: 'Exporter',
                            md: '/guides/gedcom/export-file'}
                    ]}
                ]},
        {
            title: 'FAQ',
            md: '/faq',
            children: []}
    ]};
export default navigationRoot;