import {SET_LANGUAGE} from "../actions/languageActions";

const initialState = {
    currentLanguage: ''
};
function languageReducer(state = initialState, action: any) {
    if (action.type === SET_LANGUAGE) {
        return Object.assign({}, state,
            { currentLanguage: action.payload.language});
    }
    return state;
};
export default languageReducer;