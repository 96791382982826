import Typography from 'typography'
//import Wordpress2016 from 'typography-theme-wordpress-2016'
//other themes, see:
//http://kyleamathews.github.io/typography.js/
//other fonts, see:
//https://fonts.google.com/specimen/Merriweather?selection.family=Open+Sans
import theme from './theme';


theme.overrideThemeStyles = () => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
        backgroundColor: 'transparent',
        paddingBottom: '0'
    }
  }
}

//delete Wordpress2016.googleFonts

//const typography = new Typography(Wordpress2016)
const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
