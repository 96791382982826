import * as React from 'react'
import {FaBars, FaTimes, FaWifi, FaWifiSlash} from "react-icons/fa";
import store from "../store";
import {toggleLeftPane} from "../store/actions";
import {Unsubscribe} from "redux";
import LanguageSelector from "./LanguageSelector";

interface HeaderState {
    leftPaneVisible: boolean;
    isOnline: boolean;
}

class Header extends React.Component<{}, HeaderState> {
    sub: Unsubscribe;

    constructor(props: {}) {
        super(props);
        let s = store.getState();
        this.state = {
            leftPaneVisible: s.ui.leftPane.isVisible,
            isOnline: s.connection.isOnline
        };
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();
            this.setState(Object.assign(this.state,
                {
                    leftPaneVisible: s.ui.leftPane.isVisible,
                    isOnline: s.connection.isOnline
                }));
        });
    }

    componentWillUnmount() {
        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    toggleNav = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        store.dispatch( toggleLeftPane());
    };
    render() {
        let wifiSign;
        if(this.state.isOnline) {
            wifiSign = <FaWifi/>
        } else {
            wifiSign = <FaWifiSlash/>
        }
        return (
            <div style={{
                position: 'fixed',
                top:0,left:0,right:0,
                height: '46px',
                backgroundColor:'#ffffff',
                borderBottom: '#c0c0c0 solid 1px',
                boxShadow: '0px 6px 3px -6px rgba(83,48,148,1)'}}>
                <div id="headerBars"
                     onClick={(e) => this.toggleNav(e)}
                    style={{
                        cursor: 'pointer',
                        padding: '10px 15px 5px',
                        fontSize: '1.5em',
                        lineHeight: '30px',
                        verticalAlign: 'top',
                    borderRight: 'solid 1px #c0c0c0'}}>
                    <span style={{display: this.state.leftPaneVisible ? 'none' : 'block'}}><FaBars/></span>
                    <span style={{display: this.state.leftPaneVisible ? 'block' : 'none'}}><FaTimes/></span>
                </div>
                <div style={{display: 'inline-block', position:'absolute', width: '200px'}}>
                    <div style={{
                        display: 'block',
                        fontFamily: 'Lato',
                        fontSize: '1.2em',
                        fontWeight: 600,
                        position:'absolute',
                        top:'-3px',
                        left:'15px' }}>help.dadbox.eu</div>
                    <div style={{
                        display: 'block',
                        fontFamily: 'monospace',
                        position:'absolute',
                        top:'22px',
                        left:'15px',
                        color:'#868686'}}>user documentation</div>
                </div>
                <div style={{
                    display: 'none',//'inline-block',
                    position:'absolute',
                    right: '120px',
                    top: '11px'}}>{wifiSign}</div>
                <div style={{
                    display: 'inline-block',
                    position:'absolute',
                    right: '10px',
                    top: '11px'}}>
                    <LanguageSelector/>
                </div>
            </div>
        )
    }
}

export default Header
