import * as React from 'react'
import { WindowLocation } from '@reach/router'
import { navigate } from "gatsby";
import store from "../store";
import "./stylesheet.css"
import { rhythm } from '../utils/typography'
import Header from "./Header";
import Tree from "./tree-view/Tree";
import {initLeftPane, initTreeView, resizeWindowLeftPane, setCurrentNode, toggleLeftPane} from "../store/actions";
import {Unsubscribe} from "redux";
import * as AppConstants from "../constants";
import BreadCrumb from "./BreadCrumb";
import TreeNavigator from "./TreeNavigator";
import navigationRoot from './tree-view/nav-data';
import ChildrenNavigator from "./ChildrenNavigator";
import {setLanguage} from "../store/actions/languageActions";
import {ALLOWED_LANGUAGES, DEFAULT_LANGUAGE} from "../constants";
import {setConnection} from "../store/actions/connectionActions";

interface LayoutProps {
    location: WindowLocation;
    title: string;
}
interface LayoutState {
    leftPaneVisible: boolean;
    displayBackDrop: boolean;
    location: WindowLocation;
    language: string;
}

class Layout extends React.Component<LayoutProps, LayoutState> {
    sub: Unsubscribe;
    constructor(props: LayoutProps) {
        super(props);
        let s = store.getState();
        this.state = {
            leftPaneVisible: s.ui.leftPane.isVisible,
            displayBackDrop: s.ui.leftPane.displayBackDrop,
            location: props.location,
            language: s.language.currentLanguage};

        //init navigation tree view
        store.dispatch(initTreeView(navigationRoot));
    }

    componentDidMount() {
        this.sub = store.subscribe(() => {
            let s = store.getState();
            this.setState(Object.assign(this.state,
                {
                    leftPaneVisible: s.ui.leftPane.isVisible,
                    displayBackDrop: s.ui.leftPane.displayBackDrop,
                    language: s.language.currentLanguage
                }));
            //console.log(this.state);
        });

        window.addEventListener("resize", this.resizeWindow);

        //init the left pane store
        store.dispatch(initLeftPane(AppConstants.MIN_WIDTH_DISPLAY_LEFT_PANE, window.innerWidth));

        //if not set, init the language from the browser settings
        this.setLanguageFromBrowser();

        //add an asynchronous listener to check the internet connection
        //this.checkInternetConnection();

        //redirect to start page
        if(this.state.location.pathname === '/') {
            let s = store.getState();
            const startPath = '/introduction';
            const url = `/${s.language.currentLanguage}${startPath}`;
            // navigate to the page
            navigate(url);
            //set the current node
            store.dispatch(setCurrentNode(url));
        } else {
            // if the location is different from the one in the store, then
            // change the current node of the store.
            // There is a listener to the current node in the Tree component
            // to reflect the change and display the active node.
            let s = store.getState();
            if (s.ui.treeView.currentNode != undefined) {
                let storePath = this.removeTrailingSlash(s.ui.treeView.currentNode.md);
                let locationPath = this.removeTrailingSlash(this.state.location.pathname);
                //console.log('storePath: ' + storePath);
                //console.log('locationPath: ' + locationPath);
                if(storePath !== locationPath) {
                    //console.log(this.state.location.pathname);
                    store.dispatch(setCurrentNode(locationPath));
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeWindow);

        if(this.sub != undefined) {
            //unsubscribe to the store
            this.sub();
        }
    }

    setLanguageFromBrowser() {
        //do something if the language is not set only:
        //i.e: only one time when the app is started
        if(this.state.language.length > 0) return;

        //read the language from the browser settings
        let language = window.navigator.language;

        //check length
        if(language.length < 2) {
            //english is the default language
            store.dispatch(setLanguage(DEFAULT_LANGUAGE));
            //nothing else to do: exotic language format
            return;
        }

        //take the first 2 characters only:
        //en-GB -> en
        //de-DE -> de
        language = language.substring(0, 2).toLowerCase();

        //set the language only if it belongs to the list of authorized languages
        if(ALLOWED_LANGUAGES.indexOf(language) > -1) {
            //the language is allowed, set it as current language
            store.dispatch(setLanguage(language));
        } else {
            //otherwise: english is the default language
            store.dispatch(setLanguage(DEFAULT_LANGUAGE));
        }
    }

    checkInternetConnection() {
        const xhr = new XMLHttpRequest();
        const file = this.fileToCheck();
        const randomNum = Math.round(Math.random() * 10000);

        xhr.open('HEAD', file + "?rand=" + randomNum, true);
        xhr.send();

        const processRequest = ()=> {
            if (xhr.readyState == 4) {
                if (xhr.status >= 200 && xhr.status < 304) {
                    store.dispatch(setConnection(true));
                    //this.setState(Object.assign(this.state, {isOnline: true}));
                    //console.log("connection exists!");
                } else {
                    store.dispatch(setConnection(false));
                    //this.setState(Object.assign(this.state, {isOnline: false}));
                    //console.log("connection doesn't exist!");
                }
            }
        };
        xhr.addEventListener("readystatechange", processRequest, false);
    }

    fileToCheck(): string {
        // check for the presence of: 'http://help.dadbox.eu/check-connection'
        return `${location.protocol}//${location.host}/check-connection`;
    }

    removeTrailingSlash(path: string): string {
        if (path == undefined) return '';
        return path.replace(/\/$/, "");
    }

    resizeWindow() {
        //inform the left pane that the window has been resized
        store.dispatch(resizeWindowLeftPane(window.innerWidth));
    }

    closeLeftPane() {
        store.dispatch(toggleLeftPane());
    }

    onSelect = () => {};

    render() {
        const { children } = this.props;

        const leftNavModalStyle = {
            display: this.state.displayBackDrop ? 'block' : 'none',
            position: 'fixed',
            top:0,left:0,bottom:0,right:0,
            backgroundColor:'#a79ac180'
        };
        const leftNavStyle = {
            display: this.state.leftPaneVisible ? 'block' : 'none',
            position: 'fixed',
            top:'46px',left:0,bottom:0,
            width: '200px',
            backgroundColor:'#ffffff',
            borderRight: '#c0c0c0 solid 1px',
            overflowY: 'auto'
        };
        /*
        //display the location of the current page (for test only)
        let history = createHistory(window);
        let location = (
            <div id={"my-location"}>
                <div>{this.state.location.pathname}</div>
            </div>
          );*/

        return (
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(24),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            }}
          >
              <div id="rightPane"
                   style={{
                  position: 'fixed',
                  top:'46px',right:0,bottom:0,
                  backgroundColor:'#ffffff',
                  overflowY: 'scroll'}}>
                  <div id="contentPane">
                      {/*{location}*/}
                      <BreadCrumb/>
                      {children}
                      <TreeNavigator/>

                  </div>
                  <ChildrenNavigator/>
                  <div id="footer">
                      <div>dadbox, © 2019</div>
                  </div>
              </div>
              <div id="leftNavModal" style={leftNavModalStyle} onClick={this.closeLeftPane}></div>
                  <div style={leftNavStyle}>
                    <Tree onSelect={this.onSelect} />
                  </div>
              <Header/>
          </div>
        )
    }
}

export default Layout
